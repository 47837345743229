body {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: "Roboto Mono", sans-serif;
  /* font-family: "Open Sans", sans-serif; */
}

h1, h2, h3, h4, h5, h6 {
  /* font-family: "PT Serif", serif; */
  /* font-family: "Robot Mono", serif; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 1rem;
  color: red;
}
input[type=file] {
  width: 100%;
}

input[type="text"]
{
  font-family: "Roboto Mono", sans-serif;
  font-size: 1rem;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}